import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Button, Loading, TextInput } from '@epcbuilder/lib/components';
import { Address, AddressResult, PostcodeSearch } from '@epcbuilder/lib/models/properties';
import { getAddress, getAddressRetrieve } from '@epcbuilder/lib/network/properties';
import { handleFormErrors, POSTCODE_REGEX } from '@epcbuilder/lib/utils';
import { AxiosErrorData, handleUnknownDetail } from '@epcbuilder/lib/utils/api';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { FormStage } from '@/models/generic';

const postcodeSearchSchema = yup.object().shape({
  postcode: yup.string().required().matches(POSTCODE_REGEX, 'Please enter a valid postcode'),
});

const PostcodeStage = ({
  postcode,
  initialResults,
  handleAddressFromPostcode,
  setFormStage,
}: {
  postcode: string;
  initialResults: AddressResult[];
  handleAddressFromPostcode: (result: Address) => void;
  setFormStage: Dispatch<SetStateAction<FormStage>>;
}) => {
  const [results, setResults] = useState<AddressResult[]>(initialResults);
  const [addressId, setAddressId] = useState<string>('');

  const defaultValues: PostcodeSearch = {
    postcode: postcode || '',
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
  } = useForm<PostcodeSearch>({
    defaultValues,
    resolver: yupResolver(postcodeSearchSchema),
  });

  const onSubmit: SubmitHandler<PostcodeSearch> = useCallback(
    async (data: PostcodeSearch) => {
      try {
        const response = await getAddress({ postcode: data.postcode.trim() });
        setResults(response);
      } catch (error: unknown) {
        const { errors, detail } = error as AxiosErrorData;
        handleFormErrors<PostcodeSearch>(setError, errors);

        switch (detail) {
          case 'No addresses could be found':
          case 'Failed getting address':
            toast.error('There was an error, please check your postcode and try again');
            break;
          default:
            handleUnknownDetail(error);
            break;
        }
      }
    },
    [setError]
  );

  const getFullAddress = useCallback(async () => {
    const address = await getAddressRetrieve({ id: addressId });
    handleAddressFromPostcode(address[0]);
  }, [addressId, handleAddressFromPostcode]);

  useEffect(() => {
    if (addressId) {
      getFullAddress();
    }
  }, [addressId, getFullAddress]);

  if (isSubmitting) {
    return <Loading />;
  }

  return (
    <>
      <h1>Stage 1 of 4 - Select your Address</h1>
      <form className="mt-4 flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
        <TextInput
          {...register('postcode')}
          id="Postcode"
          name="postcode"
          title="Your postcode"
          placeholder="Postcode"
          error={errors.postcode?.message}
        />
        {results.length > 0 && (
          <div className="border-primary overflow-y-hidden rounded-xl border-2">
            <div className="h-48 overflow-y-auto p-2">
              {results.map((item) => (
                <button
                  type="button"
                  key={item.id}
                  className="hover:bg-primary hover:text-light cursor-pointer rounded-xl p-2 text-left"
                  onClick={() => setAddressId(item.id)}
                >
                  {item.text}
                </button>
              ))}
            </div>
          </div>
        )}
        <Button loading={isSubmitting} type="submit">
          Search
        </Button>
      </form>
      <button type="button" className="text-link mt-4 text-center" onClick={() => setFormStage(FormStage.ADDRESS)}>
        Or set manually
      </button>
    </>
  );
};

export default PostcodeStage;
